<template>
<form @submit.prevent="">
    <slot v-if="widgetLoad">
        <div class="widget-min-box-content text-center p-2">
            <div class="widget-loading"></div>
        </div>
    </slot>
    <slot v-else>
        <div class="form-row">
        
            <!-- <v-select  v-model="selected_watchList.market_ids" class="profile-mobile-view-select mobile-view-select-modal fit-h" :items="watch_list" item-text="title"  item-value="market_ids"  outlined></v-select> -->

            <div class="col-12">
                <div class="widget-select w-100 m-top widget-select-lg">
                    <v-select2 v-model="selected_watchList" :clearable="false" label="title" :options="watch_list">
                    </v-select2>
                </div>
            </div>
        </div>
        <div class="form-row text-center mt-4" v-if="selected_watchList.market_ids">
            <slot v-if="detectSymbol(selected_watchList.market_ids)">
                <span class="m-left min-w150 tgju-btn tgju-btn-lg tgju-btn-danger" @click="saveChanges(selected_watchList.id, selected_watchList.market_ids)">
                    <div class="fa fa-trash l-item-btn-pages"></div>
                    حذف
                </span> 
            </slot>
            <slot v-else>
                <span class="m-left min-w150 tgju-btn tgju-btn-lg tgju-btn-primary" @click="saveChanges(selected_watchList.id, selected_watchList.market_ids)">
                    <div class="fa fa-plus l-item-btn-pages"></div>
                    افزودن
                </span>
            </slot>
        </div>
        <!-- <div class="form-row">
            <div class="col-12">
                <table class="table table-hover text-center">
                    <thead class="text-center">
                        <tr style="width: 618px;">
                            <th>نام دسته</th>
                            <th style="width: 20px;"></th>
                        </tr>
                    </thead>
                    <tbody class="table-padding-sm">
                        <tr v-for="item in watch_list" :key="item.id">
                            <td>
                                {{ item.title }}
                            </td>
                            <td>
                                <slot v-if="detectSymbol(item.market_ids)">
                                    <span class="btn btn-danger btn-sm list-col-left-btn" @click="saveChanges(item.id, item.market_ids)">
                                        <div class="fa fa-minus l-item-btn-pages"></div>
                                        حذف
                                    </span>
                                </slot>
                                <slot v-else>
                                    <span class="btn btn-primary btn-sm list-col-left-btn" @click="saveChanges(item.id, item.market_ids)">
                                        <div class="fa fa-plus l-item-btn-pages"></div>
                                        افزودن
                                    </span>
                                </slot>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div> -->
    </slot>
</form>
</template>

<script>
// این کامپوننت مودال افزودن نماد به واچ لیست در لیست بازار ها می باشد
export default {
    name: 'MarketAddToWatchList',
    props: ['data'],
    components: {},
    data: function () {
        return {
            widgetLoad: true,
            watch_list: [],
            selected_watchList: [],
        }
    },
    mounted() {
        this.$watch('data', function (newValue, oldValue) {
            this.widgetLoad = true;
            this.getWatchList()
        }, { immediate: true });
    },
    methods: {
        // ارسال اطلاعات با پرنت جهت کال بک
        saveChanges(id, symbols) {
            this.$parent.emitData('addMarketToWatchList', { category_id: id, is_for_add: this.detectSymbol(symbols) }, true);
        },
        // دریافت لیست واج لیست های کاربر
        getWatchList() {
            this.$helpers.makeRequest('GET', '/watchlist/list').then(api_response => {
                if (api_response.status == 200) {
                    this.widgetLoad = false;
                    this.watch_list = api_response.data.response.watch_lists;
                }
            });
        },
        // شناسایی اینکه آیا کاربر قبلاً از شاخص داخل لیست افزودن است یا خیر
        detectSymbol(array) {
            return array.includes(this.data.symbol.toString());
        },
    },
}
</script>
